html {
  min-height: 100%;
}

header {
  overflow: visible;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.right .popup {
  position: absolute;
  right: 0;
  top: 55px;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  width: 200%;
  border: 1px solid var(--orange);
}

.right .popup a {
  padding: .5rem;
  height: 40px;
  z-index: 1024;
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1023;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
}

form {
  padding: 20px;
}

form#addNewEntry{
  padding: 0 20px;
}

form button, form h5 {
  margin: 20px 0;
}

.container {
  max-width: 1200px;
  margin: 2rem .5rem 4rem;
}

.container-xl {
  max-width: 1500px;
  margin: 2rem .5rem 4rem;
}

.container-xl.xxl {
  max-width: 1800px;
}

@media (min-width: 576px) { 
  .container {
    margin: 2rem auto 4rem;
  }

  .container-xl {
    margin: 2rem auto 4rem;
  }
}

.dashboard-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.dashboard-grid > div {
  width: 150px;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid var(--orange);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  cursor: pointer;
}


.dashboard-grid > div > img {
  object-fit: contain;
  height: 100px;
  width: 100px;
}

@media (min-width: 768px) { 
  .dashboard-grid > div {
    width: 300px;
  }

  .dashboard-grid > div > img {
    height: 150px;
    width: 200px;
  }
}

.dashboard-grid > div > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-grid > div p {
  font-size: 14px;
}

.dashboard-grid > div button {
  border: 2px solid var(--blue);
  background-color: var(--blue);
  color: white;
  margin-right: .5rem;
}

.login {
  text-align: center;
}

.toast {
  background-color: white;
}

@media (max-width: 576px) { 
  fieldset > div {
    grid-template-columns: auto;
  }
}

table input {
  width: 100%
}

table.timesheet td:last-child{
  font-size: 14px;
}

.table-container {
  width: 100%;
  height: 100%;
}

table tr.newDate td{
  border-top: 0.1rem solid green;
}

fieldset.flex > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

fieldset.flex > div > label.text-center {
  display: inline-block;
  text-align: center;
}

fieldset.flex > div > * {
  width: auto;
  margin: .5rem .5rem;
}

fieldset.flex .form-group > *{
  margin-left: .25rem;
  margin-right: .25rem;
}

table select {
  width: 100%;
}

/* CUSTOMIZE DATERANGEPICKER */

.react-daterange-picker {
  width: 100%;
}

.react-daterange-picker button:focus {
  color: var(--black);
}

.react-timerange-picker__wrapper {
  height: 36px;
}

.react-timerange-picker__wrapper > .react-timerange-picker__inputGroup {
  min-width: auto;
}

.react-daterange-picker__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.react-daterange-picker__wrapper button {
  display: none;
}

.react-daterange-picker__range-divider {
  display: none;
}

.teammate-profile {
  margin: 1rem;
  padding: 1rem;
  border: 2px solid var(--orange);
}

.teammate-profile div {
  margin-bottom: .5rem;
}

.teammate-profile div p {
  margin-bottom: 0;
  margin-left: 1rem;
}

.teammate-profile .alias {
  font-size: 28px;
  font-weight: bold;
  color: var(--blue);
  margin-bottom: 1rem;
}

.teammate-profile .profile-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.teammate-profile .tooltip {
  display: flex;
  align-items: center;
}

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  top: -40px;
  visibility: hidden;
  width: 160px;
  background-color: var(--blue);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-size: 14px;
}

.tooltip.tooltip-small {
  display: inline;
}

.tooltip.tooltip-small .tooltiptext {
  width: 50px;
  left: -100%;
  padding: 2px 0;
  font-size: 12px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}


.calendar-modal {
  height: 100%;
}

.exit-modal {
  position: absolute;
  top: 0;
  right: 0;
}

.calendar-modal-iframe {
  height: 500px;
  width: 100%;
  padding: 1rem;
}

.submit-new-entry {
  margin-left: auto;
}

.alert-danger {
  padding: .5rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.timesheet .operations > * {
  margin: 0 .25rem;
  cursor: pointer;
}

.timesheet .entry-selected > td {
  background-color: var(--yellow);
}

.accounts-foglio-modal > * {
  margin: 0 .5rem;
}

button.active {
  background: var(--blue);
  color: var(--white);
  border-color: var(--yellow);
}
button.button-as-link {
  text-align: left;
  text-decoration: underline;
  color: var(--blue);
  background-color: transparent;
  border: none;
}

button.button-as-link:focus,
button.button-as-link:active {
  border: none;
  outline: none;
}

.w-100 {
  width: 100%;;
}

.entries-per-page {
  width: 100%;
  display: flex;
  align-items: center;
}

.entries-per-page select {
  margin-left: .25rem;
  margin-right: .25rem;
}

.search {
  width: 100%;
}

.search form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search input {
  margin-left: .5rem;
  margin-right: .5rem;
  width: 100%;
  max-width: 500px;
}

tr.deleted, tr.deleted button.button-as-link {
  color: red;
  text-decoration: none;
}
